import React, { useEffect, useState } from 'react'
import { Link, useNavigate  } from 'react-router-dom';
import './Footer.scss'
import { Griditem, Gridwrapper, RedHatDisplay, Roboto } from '../Styled'
import useScroll from '../../hooks/ScrollNavigate';

const Footer = () => {
  const { scrollTo } = useScroll(); // Get the scrollTo function from react-use-scroll
  const navigate = useNavigate(); // Get navigate function from React Router

  const handleLinkClick = (to) => {
    scrollTo({ top: 0 }); // Scroll to top before navigating
    navigate(to); // Navigate to the desired page
  };

  let logo = require('../../assets/AUTO_DLG_tu_taller_de_confianza_footer.png')

  // Estado para determinar si estamos en una pantalla móvil
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  // Efecto para manejar cambios de tamaño de pantalla
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Función para obtener el estilo de marginLeft condicionalmente
  const getMarginLeftStyle = () => {
    return isMobile
        ? { marginLeft: 'unset', margin: '30px auto' }
        : { marginLeft: 'auto', margin: 'auto' };
};
  const getBorderStyle = () => {
    return isMobile ? 'unset' : '1px solid white';
  };

  return (
    <div className='footer-aut-dlg'>
      <Gridwrapper
        varwidth = "95%"
        varmaxwidth = "1136px"
        gridTemplateColumns = "35.6% 64.3%"
        padd = '0 0 35px'
        columnsMovil600 = '100%'
      >
        <Griditem
          displayMovil = 'flex'
          style={{ borderRight: getBorderStyle() }}
        >
         <Link to='/' onClick={() => handleLinkClick('/')}>
            <img src={logo} alt='Auto DLG' className='logo' />
         </Link> 
        </Griditem>
        <Griditem>
          <Gridwrapper
            varwidth = "80%"
            varmaxwidth = "554px"
            gridTemplateColumns = "22.74% 23.10% 23.10%"
            varGridGap = "16.06%"
            varalignItems = 'start'
            columnsMovil600 = '28.74% 28.10% 16.1%'
            movilWidth = '100%'
            gridgapMovil = '9.0'
            movilsmWidth = '90%'
            style={getMarginLeftStyle() }
          >
            <Griditem>
              <RedHatDisplay
                color ='#ffffff'
                weight = '800'
                textAlign = 'left'
                margin = '0 0 20px'
                smFontSize = '14px'
              >
                Servicios
              </RedHatDisplay>
              <Link to ='/mantenimiento' onClick={() => handleLinkClick('/mantenimiento')}>
                <RedHatDisplay
                  fontSize = "16px"
                  color='#ffffff'
                  weight = '400'
                  textAlign = 'left'
                  margin = '0 0 7px'
                  smFontSize = '11px'
                >
                  Mantenimiento
                </RedHatDisplay>
              </Link>
              <Link to='/escaner' onClick={() => handleLinkClick('/escaner')}>
                <RedHatDisplay
                  fontSize = "16px"
                  color='#ffffff'
                  weight = '400'
                  textAlign = 'left'
                  margin = '0 0 7px'
                  smFontSize = '11px'
                >
                  Escáner
                </RedHatDisplay>
              </Link>
              <Link to='/suspension' onClick={() => handleLinkClick('/suspension')}>
                <RedHatDisplay
                  fontSize = "16px"
                  color='#ffffff'
                  weight = '400'
                  textAlign = 'left'
                  margin = '0 0 7px'
                  smFontSize = '11px'
                >
                  Suspensiones
                </RedHatDisplay>
              </Link>
              <Link to='/inyectores' onClick={() => handleLinkClick('/inyectores')}>
                <RedHatDisplay
                  fontSize = "16px"
                  color='#ffffff'
                  weight = '400'
                  textAlign = 'left'
                  margin = '0 0 7px'
                  smFontSize = '11px'
                >
                  Inyectores
                </RedHatDisplay>
              </Link>
              <Link to='/direccion' onClick={() => handleLinkClick('/direccion')}>
                <RedHatDisplay
                  fontSize = "16px"
                  color='#ffffff'
                  weight = '400'
                  textAlign = 'left'
                  margin = '0 0 7px'
                  smFontSize = '11px'
                >
                  Dirección
                </RedHatDisplay>
              </Link>
              <Link to='/enfriamiento' onClick={() => handleLinkClick('/enfriamiento')}>
                <RedHatDisplay
                  fontSize = "16px"
                  color='#ffffff'
                  weight = '400'
                  textAlign = 'left'
                  margin = '0 0 7px'
                  smFontSize = '11px'
                >
                  Enfriamiento
                </RedHatDisplay>
              </Link>
              <Link to='/clutch' onClick={() => handleLinkClick('/clutch')}>
                <RedHatDisplay
                  fontSize = "16px"
                  color='#ffffff'
                  weight = '400'
                  textAlign = 'left'
                  margin = '0 0 7px'
                  smFontSize = '11px'
                >
                  Clutch
                </RedHatDisplay>
              </Link>
              <Link to='/rodamiento' onClick={() => handleLinkClick('/rodamiento')}>
                <RedHatDisplay
                  fontSize = "16px"
                  color='#ffffff'
                  weight = '400'
                  textAlign = 'left'
                  margin = '0 0 7px'
                  smFontSize = '11px'
                >
                  Rodamiento
                </RedHatDisplay>
              </Link>
            </Griditem>
            <Griditem>
            <RedHatDisplay
                color ='#ffffff'
                weight = '800'
                textAlign = 'left'
                margin = '0 0 20px'
                smFontSize = '14px'
              >
                Ubicación
              </RedHatDisplay>
              <Link to='https://maps.app.goo.gl/nxLxsQu2xcFE26XC6'>
                <RedHatDisplay
                  fontSize = "16px"
                  color='#ffffff'
                  weight = '400'
                  textAlign = 'left'
                  margin = '0 0 28px'
                  smFontSize = '11px'
                  lineHeight = '1.4em'
                >
                  Calle Juan Pablos #1385 Col. Centro CP 27000 Torreon Coahuila
                </RedHatDisplay>
              </Link>
              <RedHatDisplay
                color ='#ffffff'
                weight = '800'
                textAlign = 'left'
                margin = '0 0 20px'
                smFontSize = '14px'
              >
                Contacto
              </RedHatDisplay>
              <Link to='http://wa.me/528712611030'>
                <RedHatDisplay
                  fontSize = "16px"
                  color='#ffffff'
                  weight = '400'
                  textAlign = 'left'
                  margin = '0 0 7px'
                  smFontSize = '11px'
                >
                  +52 871 261 1030
                </RedHatDisplay>
              </Link>
            </Griditem>
            <Griditem>
            <RedHatDisplay
                color ='#ffffff'
                weight = '800'
                textAlign = 'left'
                margin = '0 0 20px'
                smFontSize = '14px'
              >
                Redes
              </RedHatDisplay>
              <Link href='../Bottom/Bottom.jsx'>
                <RedHatDisplay
                  fontSize = "16px"
                  color='#ffffff'
                  weight = '400'
                  textAlign = 'left'
                  margin = '0 0 7px'
                  smFontSize = '11px'
                >
                  Instagram
                </RedHatDisplay>
              </Link>
              <Link href='../Bottom/Bottom.jsx'>
                <RedHatDisplay
                  fontSize = "16px"
                  color='#ffffff'
                  weight = '400'
                  textAlign = 'left'
                  margin = '0 0 7px'
                  smFontSize = '11px'
                >
                  Facebook
                </RedHatDisplay>
              </Link>
              <Link to='http://wa.me/528712611030'>
                <RedHatDisplay
                  fontSize = "16px"
                  color='#ffffff'
                  weight = '400'
                  textAlign = 'left'
                  margin = '0 0 7px'
                  smFontSize = '11px'

                >
                  WhatsApp
                </RedHatDisplay>
              </Link>
            </Griditem>

          </Gridwrapper>
        </Griditem>
      </Gridwrapper>
      <Gridwrapper
        varwidth = "95%"
        varmaxwidth = "1248px"
        gridTemplateColumns = "50% 50%"
        style={{ borderTop: '1px solid white'}}
        padd = "16px 0"
        varmargin = '29px 0 0'
        columnsMovil600 = '100%'
      >
        <Griditem>
          <Roboto
            fontSize = "14px"
            varcolor = "#ffffff"
            weight = '300'
            textAlign = "left"
            textAlignMovil = 'center'
            fontSizeMobile600 = '12px'
          >
            ©2024 Todos los derechos reservados -- Aviso de privacidad
          </Roboto>
        </Griditem>
        <Griditem>
          <Roboto
            fontSize = "14px"
            varcolor = "#ffffff"
            weight = '300'
            textAlign = "right"
            textAlignMovil = 'center'
            fontSizeMobile600 = '12px'
          >
            Creada por&nbsp;
            <Link 
            to='https://ingenieriadigital.mx'
            className='linkDecoration'
            >
              Ingeniería Digital 
            </Link>
          </Roboto>
        </Griditem>
      </Gridwrapper>
    </div>
  )
}

export default Footer