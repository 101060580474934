import React from 'react';
import CustomizedAccordions from '../Accordion';
import { RedHatDisplay } from '../Styled';

function AcordeonMantenimiento() {
    // Inicializa el estado de expansión en 'true' para que el acordeón esté abierto al cargar la página
    const [expanded, setExpanded] = React.useState(true);

    // Los detalles para AccordionDetails
    const details = [
        <RedHatDisplay
            fontSize="18px"
            fontSizeMobile="14px"
            textAlign="left"
            lineHeight="1.2em"
            smFontSize= '12px'
        >
            <strong><em>Mantenimiento Menor y Mayor:</em></strong> Un chequeo completo que garantiza el buen funcionamiento de tu vehículo.
        </RedHatDisplay>,
        <RedHatDisplay
            fontSize="18px"
            fontSizeMobile="14px"
            textAlign="left"
            lineHeight="1.2em"
            smFontSize= '12px'
        >
            <strong><em> Afinación Menor y Mayor:</em></strong> Asegura el rendimiento óptimo y la eficiencia de combustible de tu automóvil.
        </RedHatDisplay>,
        <RedHatDisplay
            fontSize="18px"
            fontSizeMobile="14px"
            textAlign="left"
            lineHeight="1.2em"
            smFontSize= '12px'
        >
            <strong><em> Cambio de Aceite:</em></strong> Utilizamos productos de alta calidad para mantener tu motor en excelentes condiciones.
        </RedHatDisplay>,
        <RedHatDisplay
            fontSize="18px"
            fontSizeMobile="14px"
            textAlign="left"
            lineHeight="1.2em"
            smFontSize= '12px'
        >
            <strong><em> Cambio de Bujías:</em></strong> Fundamental para el arranque suave y eficiente de tu coche.
        </RedHatDisplay>
    ];

    // Función para manejar el cambio de expansión del acordeón
    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded ? true : false);
    };

    return (
        <CustomizedAccordions
            header={
                <RedHatDisplay
                    fontSize="20px"
                    weight="700"
                    lineHeight="1.3em"
                    color="#fff"
                    smFontSize= '12px'
                >
                    Nuestros servicios de Mantenimiento Incluyen:
                </RedHatDisplay>
            }
            details={details}
            expanded={expanded}
            onChange={handleAccordionChange}
        />
    );
}

export default AcordeonMantenimiento;