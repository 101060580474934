import React from 'react'

const Formulario = () => {
  return (
    <div>
        <iframe
                src="https://link.superleads.mx/widget/form/my6AIRhBhalJWyfBtP7G"
                style={{width:'100%', height:'65vh', border:'none', borderRadius:'4px', marginBottom: '-100px'}}
                id="inline-my6AIRhBhalJWyfBtP7G" 
                data-layout="{'id':'INLINE'}"
                data-trigger-type="alwaysShow"
                data-trigger-value=""
                data-activation-type="alwaysActivated"
                data-activation-value=""
                data-deactivation-type="neverDeactivate"
                data-deactivation-value=""
                data-form-name="Marketing Form - Claim Offer"
                data-height="722"
                data-layout-iframe-id="inline-my6AIRhBhalJWyfBtP7G"
                data-form-id="my6AIRhBhalJWyfBtP7G"
                title="Marketing Form - Claim Offer"
        >
        </iframe>
        <script src="https://link.superleads.mx/js/form_embed.js"></script>
    </div>
  )
}

export default Formulario