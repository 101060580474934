import React from 'react'
import './Visitanos.scss'
import { BtnRed, Griditem, Gridwrapper, RedHatDisplay } from '../Styled'
import { Link } from 'react-router-dom'

const Visitanos = () => {
  let arrow = require('../../assets/arrows_left.png')
  let logoDLG = require('../../assets/AUTO_DLG_logo.png')

  const handleWhatsAppClick = () => {
    const phoneNumber = '528712611030'; // Replace with the actual phone number
    const whatsAppLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsAppLink, '_blank'); // Opens WhatsApp chat in a new tab
  };
  const handleDirectionClick = () => {
    const DirectionLink = `https://maps.app.goo.gl/uTcQhg5dqaTm87TN8`;
    window.open(DirectionLink, '_blank'); // Opens WhatsApp chat in a new tab
  };

  return (
    <section className='section-visit-us'>
        <Gridwrapper
          varwidth = "90%"
          varmaxwidth = "1255px"
          varGridGap = "6.2%"
          gridTemplateColumns = "40% 52%"
          columnsMovil600 = '100%'
          marginMovil = '76px 0'
        >
            <Griditem>
              <img src={arrow} alt="arrow-red" style={{width: '65px', marginBottom: '16px'}} />
              <RedHatDisplay
                fontSize = "60px"
                color ='black'
                weight = '700'
                lineHeight = "1.2em"
                margin = "0 0 16px 0"
                textAlign = 'left'
                smFontSize = '32px'
              >
                Visítanos
              </RedHatDisplay>
              <RedHatDisplay
                fontSize = "30px"
                color ='black'
                weight = '400'
                lineHeight = "1.2em"
                textAlign = 'left'
                smFontSize = '20px'
              >
                AutoDLG
              </RedHatDisplay>
              <RedHatDisplay
                fontSize = "30px"
                color ='black'
                weight = '800'
                lineHeight = "1.2em"
                textAlign = 'left'
                smFontSize = '16px'
              >
                Calidad al alcance de todos.
              </RedHatDisplay>
              <BtnRed
                type='button'
                onClick={handleDirectionClick}
                varwidth = "40%"
                varmaxwidth = "178px"
                varmargin = "16px 0 0 0"
                fontSizeMobile = '12px'
                smPadd = '10px 25px'
                smWidth  = '44%'
              >
                Dirección al Taller
              </BtnRed>
              <BtnRed
                type='button'
                onClick={handleWhatsAppClick}
                varwidth = "60%"
                varmaxwidth = "229px"
                vargridgap = "8px"
                padding = "12px 20px"
                varmargin = "16px 0 16px 0"
                fontSizeMobile = '12px'
                smPadd = '10px 25px'
                smWidth  = '57%'
              >
                Solicitar Información
                <svg className='whatsappIcon' viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.5 20.8438C15.5741 20.8438 19.6875 16.7304 19.6875 11.6563C19.6875 6.58213 15.5741 2.46875 10.5 2.46875C5.42588 2.46875 1.3125 6.58213 1.3125 11.6563C1.3125 13.304 1.74628 14.8505 2.50587 16.1876L1.3125 20.8438L6.11288 19.7306C7.41653 20.4404 8.91117 20.8438 10.5 20.8438ZM10.5 19.4303C14.7935 19.4303 18.274 15.9497 18.274 11.6563C18.274 7.36277 14.7935 3.88221 10.5 3.88221C6.20652 3.88221 2.72596 7.36277 2.72596 11.6563C2.72596 13.314 3.24483 14.8505 4.12902 16.1123L3.43269 18.7236L6.08995 18.0592C7.34302 18.9239 8.86238 19.4303 10.5 19.4303Z" fill="#BFC8D0"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M10.5 20.1875C15.5741 20.1875 19.6875 16.0741 19.6875 11C19.6875 5.92588 15.5741 1.8125 10.5 1.8125C5.42588 1.8125 1.3125 5.92588 1.3125 11C1.3125 12.6478 1.74628 14.1942 2.50587 15.5314L1.3125 20.1875L6.11288 19.0744C7.41653 19.7842 8.91117 20.1875 10.5 20.1875ZM10.5 18.774C14.7935 18.774 18.274 15.2935 18.274 11C18.274 6.70652 14.7935 3.22596 10.5 3.22596C6.20652 3.22596 2.72596 6.70652 2.72596 11C2.72596 12.6577 3.24483 14.1943 4.12902 15.4561L3.43269 18.0673L6.08995 17.403C7.34302 18.2677 8.86238 18.774 10.5 18.774Z" fill="white"/>
                <path d="M8.20314 6.73441C7.9847 6.29565 7.6496 6.3345 7.31107 6.3345C6.70606 6.3345 5.7627 7.05918 5.7627 8.40789C5.7627 9.51323 6.24976 10.7232 7.89102 12.5332C9.47496 14.28 11.5562 15.1836 13.2839 15.1528C15.0117 15.1221 15.3672 13.6352 15.3672 13.1331C15.3672 12.9106 15.2291 12.7995 15.134 12.7694C14.5452 12.4868 13.4593 11.9603 13.2122 11.8614C12.9651 11.7624 12.8361 11.8962 12.7559 11.969C12.5318 12.1826 12.0876 12.8119 11.9356 12.9534C11.7835 13.095 11.5567 13.0233 11.4624 12.9698C11.1152 12.8305 10.1738 12.4117 9.4234 11.6843C8.49537 10.7847 8.4409 10.4752 8.26606 10.1997C8.12619 9.97927 8.22883 9.84404 8.28004 9.78495C8.47999 9.55423 8.75608 9.19804 8.8799 9.02103C9.00371 8.84402 8.90542 8.57528 8.84644 8.40789C8.59279 7.68802 8.37789 7.08541 8.20314 6.73441Z" fill="white"/>
                </svg>

              </BtnRed>
              <img src={logoDLG} alt="logo_dlg" style={{ width: '50%', maxWidth: '118px', marginBottom: '16px'}} />
              <Link to='https://maps.app.goo.gl/nxLxsQu2xcFE26XC6'>
                <RedHatDisplay
                  fontSize = "16px"
                  color ='black'
                  weight = '400'
                  lineHeight = "1.2em"
                  varpadding = '16px'
                  maxWidth = '206px'
                  width = '50%'
                  style={{ backgroundColor: '#ffffff81', borderRadius: '16px'}}
                  smFontSize = '12px'
                >
                  Calle Juan Pablos #1385
                  Col. Centro
                  CP 27000
                  Torreon Coahuila
                </RedHatDisplay>
              </Link>
              
            </Griditem>
            <Griditem>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2449.090046734708!2d-103.43298777440111!3d25.554613916611036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868fdbcbcd918279%3A0xa6e171e894c50d5!2sCalle%20C.%20Juan%20Pablos%201385%2C%20Tercero%20de%20Cobi%C3%A1n%20Centro%2C%2027000%20Torre%C3%B3n%2C%20Coah.!5e0!3m2!1ses!2smx!4v1713389605847!5m2!1ses!2smx" 
                className='mapa'
                // Inline style object
                style={{ borderRadius: "20px", border:"0px" }}
                allowFullscreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title='Mapa AutoDLG'
                ></iframe>
            </Griditem>

        </Gridwrapper>
    </section>
  )
}

export default Visitanos