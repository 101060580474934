import React from 'react';
import CustomizedAccordions from '../Accordion';
import { RedHatDisplay } from '../Styled';

function AcordeonRodamientos() {
    // Inicializa el estado de expansión en 'true' para que el acordeón esté abierto al cargar la página
    const [expanded, setExpanded] = React.useState(true);

    // Los detalles para AccordionDetails
    const details = [
        <RedHatDisplay
            fontSize="18px"
            fontSizeMobile="14px"
            textAlign="left"
            lineHeight="1.2em"
            smFontSize= '12px'
        >
            <strong><em>Cambio de Baleros:</em></strong> Para eliminar esos ruidos molestos y vibraciones en tus viajes.
        </RedHatDisplay>,
        <RedHatDisplay
            fontSize="18px"
            fontSizeMobile="14px"
            textAlign="left"
            lineHeight="1.2em"
            smFontSize= '12px'
        >
            <strong><em>Cambio de Rodamientos:</em></strong> Mantén tus ruedas girando suavemente y prolonga la vida útil de tus llantas.
        </RedHatDisplay>
    ];

    // Función para manejar el cambio de expansión del acordeón
    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded ? true : false);
    };

    return (
        <CustomizedAccordions
            header={
                <RedHatDisplay
                    fontSize="20px"
                    weight="700"
                    lineHeight="1.3em"
                    color="#fff"
                    smFontSize= '12px'
                >
                    Nuestros Servicios de Enfriamiento Incluyen:
                </RedHatDisplay>
            }
            details={details}
            expanded={expanded}
            onChange={handleAccordionChange}
        />
    );
}

export default AcordeonRodamientos;