import React from 'react'
import { BtnRed, ContentBox, Griditem, Gridwrapper, Hero, Littleline, Logo, RedHatDisplay } from '../components/Styled'
import './Styles/Style.scss'
import AcordeonInyectores from '../components/AcordeonServicios/AcordeonInyectores'

const Suspension = () => {
  let Hero_background = require('../assets/AUTO_DLG_Inyectores_Hero.jpg')
  let arrowLeft = require('../assets/arrows_left.png')
  let arrowRight = require('../assets/arrows_right.png')
  let logo = require('../assets/AUTO_DLG_logo_bn.png')
  let solucion = require('../assets/AUTO_DLG_Inyecyores_Importancia.png')
  let servicios = require('../assets/AUTO_DLG_Inyectores_Bottom.jpg')

  const handleDirectionClick = () => {
    const DirectionLink = `https://maps.app.goo.gl/uTcQhg5dqaTm87TN8`;
    window.open(DirectionLink, '_blank'); // Opens WhatsApp chat in a new tab
  };

  return (
    <div>
      <Hero
        varminheight='603px'
        style={{backgroundImage: `url(${Hero_background})`}}      >
          <Gridwrapper
            varwidth = "90%"
            varmaxwidth = '976px'
            varGridGap = "10px"
            gridTemplateColumns = "6.6% 84.6% 6.6%"
            columnsMovil = '10.6% 71.6% 10.6%'
          >
            <Griditem>
                <img src={arrowLeft} alt="arrow left" />
            </Griditem>
            <Griditem>
             <RedHatDisplay
                fontSize = "46px"
                color ='white'
                fontStyle = "italic"
                lineHeight = "1.2em"
                fontSizeMobile600 = '26px'
                movilLineHeight = '1.4em'
              >
                Revitalizamos la Potencia de tu Motor para un Consumo Eficiente
              </RedHatDisplay>
            </Griditem>
            <Griditem>
                <img src={arrowRight} alt="arrow rigth" />
            </Griditem>
          </Gridwrapper>
          <Logo 
                    varwidth = '60%'
                    varmaxwidth = '136px'
                    padd = '26px 0 0 0'
                    src={logo} alt="Logo AutoDLG"
          />
          <RedHatDisplay
            backgcolor = '#ffffff'
            maxWidth = '815px'
            width = '48%'
            fontSize = '20px'
            color = '#000000'
            varpadding = '20px 68px'
            margin = '31px 0 0'
            lineHeight = '1.3em'
            fontSizeMobile600 = '12px'
            widthMovil = '71%'
            MovilPadd = '20px 37px'
          >
            Los inyectores juegan un papel crucial en el rendimiento de tu motor. Con el tiempo, la suciedad y los residuos pueden obstruirlos, afectando la eficiencia y potencia de tu carro. En AutoDLG, te ofrecemos servicios avanzados de limpieza de inyectores, incluyendo limpieza por ultrasonido, para asegurar que tu motor funcione como debe.
          </RedHatDisplay>
      </Hero>
      <ContentBox
        display = 'flex'
        varminheight = '886px'
        Movilpadding = '81px 0 0'
        smMinHeight = '669px'
        varpadding = '60px 0 0'
        justifyContent = 'flex-end'
      >
        <ContentBox
          display = 'flex'
          alignItems = 'flex-end'
        >
          <RedHatDisplay
            fontSize = '28px'
            weight = '800'
            lineHeight = '1.5em'
            color='#F51313'
            justify = 'center'
            display = "flex"
            textAlign = 'left'
            smFontSize = '20px'
            smTextAlign = 'center'
          >
           ¿Por Qué es Importante Limpiar los Inyectores?
          </RedHatDisplay>
          <Littleline
            backgroundcolor = "#000"
            varminheight = "4px"
            varwidth = "70%"
            varmaxwidth = '188px'
            margin = '6px 0 30px'
          />
        </ContentBox>
        
          <Gridwrapper
                varwidth = "100%"
                varmaxwidth = "1250px"
                varmargin = '38px 0 65px'
                varGridGap = "6.5%"
                gridTemplateColumns = "27.6% 30.08% 29.2%"
                varalignItems = 'start'
                columnsMovil600 = '100%'
                movilWidth = '80%'
                marginsmMovil = '38px 0 105px'
          >
              
                <Griditem
                  backgcolor = "#FFFFFF"
                  borderRadius = '30px'
                  shadow = '0px 8px 8px rgba(119, 33, 107, 0.2)'
                  padd = "20px"
                  width = "auto"
                  maxWidth = '100%'
                >
                  
                  <RedHatDisplay
                    fontSize = '22px'
                    weight = '600'
                    lineHeight = '1.3em'
                    color='#000'
                    textAlign = 'left'
                    maxWidth = "100%"
                    fontSizeMobile = '16px'
                    smFontSize = '14px'
                  >
                    <strong>¿Pérdida de potencia en tu carro?</strong> Esto puede deberse a inyectores obstruidos.
                  </RedHatDisplay>
                </Griditem>
                <Griditem
                  backgcolor = "#FFFFFF"
                  borderRadius = '30px'
                  shadow = '0px 8px 8px rgba(119, 33, 107, 0.2)'
                  padd = "20px"
                  width = "auto"
                  maxWidth = '100%'
                >
                  
                  <RedHatDisplay
                    fontSize = '22px'
                    weight = '600'
                    lineHeight = '1.3em'
                    color='#000'
                    textAlign = 'left'
                    maxWidth = "100%"
                    fontSizeMobile = '16px'
                    smFontSize = '14px'
                  >
                    <strong>¿Mayor consumo de combustible?</strong> Los inyectores sucios pueden ser la causa.
                  </RedHatDisplay>
                </Griditem>
                <Griditem
                  backgcolor = "#FFFFFF"
                  borderRadius = '30px'
                  shadow = '0px 8px 8px rgba(119, 33, 107, 0.2)'
                  padd = "20px"
                  width = "auto"
                  maxWidth = '100%'
                >
                 
                  <RedHatDisplay
                    fontSize = '22px'
                    weight = '600'
                    color='#000'
                    textAlign = 'left'
                    maxWidth = "100%"
                    lineHeight = '1.3em'
                    fontSizeMobile = '16px'
                    smFontSize = '14px'
                  >
                    <strong>¿Arranque difícil o irregular?</strong> La limpieza de inyectores puede ser la solución.
                  </RedHatDisplay>
                </Griditem>
              </Gridwrapper>
              <img src={solucion} alt='solucion'></img>
      </ContentBox>
      <Gridwrapper
        varwidth = "100%"
        varmaxwidth = "1440px"
        varGridGap = "0%"
        gridTemplateColumns = "58.19% 41.8%"
        columnsMovil = '100%'
      >
        <Griditem>
          <ContentBox
            varpadding = '92px 96px 84px 84px'
            maxwidhtMovil = '89%'
            margin = '0px auto'
            Movilpadding = '40px 0 0'
          >
            <AcordeonInyectores />
            <Gridwrapper
              varwidth = "100%"
              varmargin = '38px 0 0px'
              varGridGap = "15px"
              gridTemplateColumns = "48.9% 48.9%"
              columnsMovilsm = '47.9% 50.9%'
              gridgapMovil = '10px'
              marginsmMovil = '38px auto'
            >
              <Griditem>
                <BtnRed 
                type='submit'
                onClick={handleDirectionClick}
                  backgcolor = '#F51313'
                  vargridgap = '8px'
                  fontSizeMobile = '12px'
                  varwidth = '-webkit-fill-available'
                >
                  Llévame al taller
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_185_845)">
                  <path d="M12.5 2C8.63 2 5.5 5.13 5.5 9C5.5 13.17 9.92 18.92 11.74 21.11C12.14 21.59 12.87 21.59 13.27 21.11C15.08 18.92 19.5 13.17 19.5 9C19.5 5.13 16.37 2 12.5 2ZM12.5 11.5C11.12 11.5 10 10.38 10 9C10 7.62 11.12 6.5 12.5 6.5C13.88 6.5 15 7.62 15 9C15 10.38 13.88 11.5 12.5 11.5Z" fill="white"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_185_845">
                  <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                  </clipPath>
                  </defs>
                  </svg>

                </BtnRed>
              </Griditem>
              <Griditem>
              <BtnRed 
                  backgcolor = '#F51313'
                  vargridgap = '8px'
                  fontSizeMobile = '12px'
                  varwidth = '-webkit-fill-available'
                >
                  Agenda tu servicio
                  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10 20.1875C14.8325 20.1875 18.75 16.0741 18.75 11C18.75 5.92588 14.8325 1.8125 10 1.8125C5.16751 1.8125 1.25 5.92588 1.25 11C1.25 12.6478 1.66312 14.1942 2.38655 15.5314L1.25 20.1875L5.82179 19.0744C7.06336 19.7842 8.48682 20.1875 10 20.1875ZM10 18.774C14.089 18.774 17.4038 15.2935 17.4038 11C17.4038 6.70652 14.089 3.22596 10 3.22596C5.91097 3.22596 2.59615 6.70652 2.59615 11C2.59615 12.6577 3.09031 14.1943 3.9324 15.4561L3.26923 18.0673L5.79996 17.403C6.99335 18.2677 8.44036 18.774 10 18.774Z" fill="white"/>
                  <path d="M7.81251 6.73429C7.60447 6.29553 7.28533 6.33438 6.96292 6.33438C6.38673 6.33438 5.48828 7.05906 5.48828 8.40777C5.48828 9.51311 5.95216 10.7231 7.51526 12.5331C9.02378 14.2798 11.0059 15.1835 12.6514 15.1527C14.2969 15.1219 14.6354 13.6351 14.6354 13.133C14.6354 12.9105 14.5039 12.7994 14.4133 12.7692C13.8525 12.4867 12.8183 11.9602 12.583 11.8612C12.3477 11.7623 12.2248 11.8961 12.1484 11.9689C11.935 12.1824 11.512 12.8117 11.3672 12.9533C11.2224 13.0948 11.0064 13.0232 10.9166 12.9697C10.5859 12.8304 9.68933 12.4116 8.97467 11.6842C8.09083 10.7846 8.03896 10.4751 7.87244 10.1996C7.73922 9.97915 7.83698 9.84392 7.88576 9.78482C8.07619 9.55411 8.33913 9.19792 8.45705 9.02091C8.57496 8.8439 8.48135 8.57515 8.42518 8.40777C8.18361 7.6879 7.97895 7.08529 7.81251 6.73429Z" fill="white"/>
                  </svg>


                </BtnRed>
              </Griditem>
            </Gridwrapper>
          </ContentBox>
        </Griditem>
        <Griditem 
          varminheight = '671px'
          tabletHeight = '452px'
          movilHeight = '352px'
          padd = '50px 0 0'
          display = 'flex'
          justifyContent = 'flex-start'
          style={{backgroundImage: `url(${servicios})`, backgroundSize: 'cover', backgroundRepeat:'no-repeat'}}
        >
          <RedHatDisplay
            fontSize = '30px'
            weight = '500'
            lineHeight = '1.3em'
            color='#fff'
            width = "80%"
            fontSizeMobile = '24px'
            smFontSize = '20px'
          >
            Mantén tu Motor en su Máximo Potencial
          </RedHatDisplay>
          <RedHatDisplay
            fontSize = '25px'
            weight = '300'
            lineHeight = '1.3em'
            color='#fff'
            width = "80%"
            fontSizeMobile = '14px'
            smFontSize = '14px'
            margin = '12px 0 0'
          >
            No dejes que inyectores sucios reduzcan el rendimiento de tu carro. Agenda una limpieza de inyectores con AutoDLG y siente la diferencia. 
          </RedHatDisplay>
        </Griditem>
      </Gridwrapper>

      
    </div>
  )
}

export default Suspension