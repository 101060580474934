import styled from "@emotion/styled/macro";

//Logo
export const Logo = styled.img`
width: ${(props) => props.varwidth || "auto"}; 
max-width: ${(props) => props.varmaxwidth || "auto"};
padding: ${(props) => props.padd || '0px'};

@media (max-width: 1000px){
  width: ${(props) => props.smWidth || props.varwidth || "auto"}; 
}
`;

export const Gridwrapper = styled.div`
  width: ${(props) => props.varwidth || "auto"}; 
  max-width: ${(props) => props.varmaxwidth || "auto"};
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplateColumns || "auto" };
  grid-gap: ${(props) => props.varGridGap || "0%"};
  margin: ${(props) => props.varmargin || "0px"};
  align-items: ${(props) => props.varalignItems || "center"};
  padding: ${(props) => props.padd || '0px'};
  justify-content: ${(props) => props.justifyContent || 'center'};

  @media (max-width: 1000px) {
    grid-template-columns: ${(props) => props.columnsMovil || props.gridTemplateColumns};
    width: ${(props) => props.tabletWidth || props.varwidth}; 
    grid-gap: 20px;
    padding: ${(props) => props.padd1000 || props.padd || '0px'};

  }
  @media (max-width: 800px) {
    grid-template-columns: ${(props) => props.columnsMovil600 || props.columnsMovil || props.gridTemplateColumns};
    width: ${(props) =>  props.movilWidth || props.tabletWidth || props.varwidth}; 
    margin: ${(props) => props.marginMovil || props.varmargin};
    grid-gap: ${(props) => props.gridgapTablet || props.varGridGap};
    padding: ${(props) => props.padd800 || props.padd1000 || props.padd || '0px'};
  }
  @media (max-width: 500px) {
    grid-template-columns: ${(props) => props.columnsMovilsm || props.columnsMovil600 || props.columnsMovil || props.gridTemplateColumns};
    width: ${(props) =>  props.movilsmWidth || props.movilWidth || props.tabletWidth || props.varwidth}; 
    margin: ${(props) => props.marginsmMovil || props.marginMovil || props.varmargin};
    grid-gap: ${(props) => props.gridgapMovil || props.gridgapTablet || props.varGridGap};
  }
`;

export const Griditem = styled.div`
  width: ${(props) => props.width || "100%"};
  max-width: ${(props) => props.maxWidth || "auto"};
  min-height: ${(props) => props.varminheight || "auto"};
  background-color: ${(props) => props.backgcolor || "none"};
  border-radius: ${(props) => props.borderRadius || "none"};
  box-shadow: ${(props) => props.shadow || 'none'};
  padding: ${(props) => props.padd || '0px'};
  display: ${(props) => props.display || 'block'};
  flex-direction: ${(props) => props.fdirection || 'column'};
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: ${(props) => props.justifyContent || 'center'};

  @media (max-width: 1000px) {
    min-height: ${(props) => props.tabletHeight || props.varminheight};
  }
  @media (max-width: 668px) {
    min-height: ${(props) => props.movilHeight || props.varminheight};
    display: ${(props) => props.displayMovil || props.display };
  }
`;

//Textos
// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 300 to 900

export const RedHatDisplay = styled.p`
  font-family: "Red Hat Display", sans-serif;
  font-optical-sizing: auto;
  font-style:  ${(props) => props.fontStyle || "normal"};
  /* Style properties based on props */
  font-weight: ${(props) => props.weight || "300"}; /* Replace with default weight */
  color: ${(props) => props.color || "#000000"};
  background-color: ${(props) => props.backgcolor || 'none'};
  font-size: ${(props) => props.fontSize || "20px"};
  padding: ${(props) => props.varpadding || "0px "};
  display: ${(props) => props.display || "block"};
  flex-wrap: ${(props) => props.wrap || "nowrap"};
  justify-content: ${(props) => props.justify || "none"};
  line-height: ${(props) => props.lineHeight || "1em"};
  text-align: ${(props) => props.textAlign || "center"};
  align-items: ${(props) => props.alignItems || "center"};
  margin: ${(props) => props.margin || "0px"};
  max-width: ${(props) => props.maxWidth || "auto"};
  width: ${(props) => props.width || "auto"};
  /* Media query for smaller screens */
  @media (max-width: 1000px) {
    font-size: ${(props) => props.fontSizeMobile || props.fontSize || "16px"}; /* Use fontSizeMobile or fallback to fontSize */
  }
  @media (max-width: 800px) {
    font-size: ${(props) => props.fontSizeMobile600 || props.fontSizeMobile || props.fontSize || "16px"}; /* Use fontSizeMobile or fallback to fontSize */
    line-height: ${(props) => props.movilLineHeight || props.lineHeight || "1em"};
    width: ${(props) => props.widthMovil || props.width};
    padding: ${(props) => props.MovilPadd || props.varpadding};
    text-align: ${(props) => props.textAlignMovil || props.textAlign };
  }
  @media (max-width: 500px) {
    font-size: ${(props) => props.smFontSize || props.fontSizeMobile600 || props.fontSizeMobile || props.fontSize || "16px"}; /* Use fontSizeMobile or fallback to fontSize */
    line-height: ${(props) => props.smlineHeight || props.movilLineHeight || props.lineHeight || "1em"};
    width: ${(props) => props.smWidth || props.widthMovil || props.width};
    padding: ${(props) => props.smPadding || props.MovilPadd || props.varpadding};
    text-align: ${(props) => props.smTextAlign || props.textAlignMovil || props.textAlign };
    color: ${(props) => props.colorMovil || props.color || "#000000"};
    margin: ${(props) => props.smMargin || props.margin || "0px"};

  }
`;

export const Roboto = styled.p`
  font-family: "Roboto", sans-serif;
  font-optical-sizing: auto;
  font-style:  ${(props) => props.fontStyle || "normal"};
  /* Style properties based on props */
  font-weight: ${(props) => props.weight || "400"}; /* Replace with default weight */
  color: ${(props) => props.varcolor || "#000000"};
  font-size: ${(props) => props.fontSize || "20px"};
  padding: ${(props) => props.varpadding || "0px "};
  display: ${(props) => props.display || "block"};
  flex-wrap: ${(props) => props.wrap || "nowrap"};
  justify-content: ${(props) => props.justify || "none"};
  line-height: ${(props) => props.lineHeight || "1em"};
  text-align: ${(props) => props.textAlign || "center"};
  align-items: center;
  margin: ${(props) => props.margin || "0px"};
  max-width: ${(props) => props.maxWidth || "auto"};
  width: ${(props) => props.width || "auto"};
  /* Media query for smaller screens */
  @media (max-width: 800px) {
    font-size: ${(props) => props.fontSizeMobile || props.fontSize || "16px"}; /* Use fontSizeMobile or fallback to fontSize */
  }
  @media (max-width: 1000px) {
    font-size: ${(props) => props.fontSizeMobile || props.fontSize || "16px"}; /* Use fontSizeMobile or fallback to fontSize */
  }
  @media (max-width: 700px) {
    font-size: ${(props) => props.fontSizeMobile600 || props.fontSize || "16px"}; /* Use fontSizeMobile or fallback to fontSize */
    line-height: ${(props) => props.movilLineHeight || props.lineHeight || "1em"};
    width: ${(props) => props.widthMovil || props.width};
    padding: ${(props) => props.MovilPadd || props.varpadding};
    text-align: ${(props) => props.textAlignMovil || props.textAlign };
  }
`;

//Botones

export const BtnRed = styled.button`
  background-color: ${(props) => props.backgcolor || "#F51313"};
  color: ${(props) => props.varcolor || "#FFFFFF"};
  padding: ${(props) => props.varpadding || "11px 17px"};
  margin: ${(props) => props.varmargin || "0px"};
  font-size: ${(props) => props.varfontsize || "16px"};
  font-family: ${(props) => props.varfontfamily || "Red Hat Display"};
  font-weight: ${(props) => props.varweight || "600"};
  min-width: ${(props) => props.varminwidth || "auto"};
  border-radius: ${(props) => props.varRadius || "8px"};
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: ${(props) => props.vargridgap || "none"};
  box-shadow: 0px 8px 8px rgba(119, 33, 107, 0.2);
  width: ${(props) => props.varwidth || "auto"};
  max-width: ${(props) => props.varmaxwidth || "auto"};
  cursor: pointer;
  :hover {
    transform: translateY(-5%);
  }
  @media (max-width: 1000px) {
    margin: ${(props) => props.varmarginMovil || props.varmargin || "0px"};
    font-size: ${(props) => props.fontSizeMobile || props.varfontsize || "16px"};
  }
  @media (max-width: 500px) {
    margin: ${(props) => props.smMargin || props.varmarginMovil || props.varmargin || "0px"};
    font-size: ${(props) => props.smFontSize || props.fontSizeMobile || props.varfontsize || "16px"};
    padding: ${(props) => props.smPadd || props.varpadding || "11px 17px"};
    width: ${(props) => props.smWidth || props.varwidth || "auto"};
  }
`;

export const BtnServicios = styled.button`
  background-color: ${(props) => props.backgcolor || "#FFFFFF"};
  color: ${(props) => props.varcolor || "#000000"};
  padding: ${(props) => props.varpadding || "12px 28px"};
  margin: ${(props) => props.varmargin || "0px"};
  font-size: ${(props) => props.varfontsize || "16px"};
  font-family: ${(props) => props.varfontfamily || "Red Hat Display"};
  font-weight: ${(props) => props.varweight || "500"};
  min-width: ${(props) => props.varminwidth || "auto"};
  border-radius: ${(props) => props.varRadius || "10px"};
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: ${(props) => props.vargridgap || "none"};
  box-shadow: 0px 8px 8px rgba(119, 33, 107, 0.2);
  width: ${(props) => props.varwidth || "-webkit-fill-available"};
  max-width: ${(props) => props.varmaxwidth || "auto"};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #FFC2C2; /* Pink on hover */
  }
  @media (max-width: 700px) {
    font-size: ${(props) => props.fontSizeMobile || props.varfontsize || "16px"};
  }
`;

//contenedores

export const Hero = styled.div`
  width: 100%;
  margin: 0%;
  min-height: ${(props) => props.varminheight || "auto"};
  padding: ${(props) => props.varpadding || "0px"};
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    padding: ${(props) => props.varpaddingm || "0px"};
  }
`;
export const ContentBox = styled.div`
  width: ${(props) => props.varwidth || "auto"};
  max-width: ${(props) => props.varmaxwidth || "auto"};
  margin: ${(props) => props.margin || "0px"};
  min-height: ${(props) => props.varminheight || "auto"};
  padding: ${(props) => props.varpadding || "0px"};
  display: ${(props) => props.display || 'block'};
  flex-direction: ${(props) => props.fdirection || 'column'};
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: ${(props) => props.justifyContent || 'center'};
  @media (max-width: 900px) {
    max-width: ${(props) => props.maxwidhtMovil || props.varmaxwidth};
    padding: ${(props) => props.varpaddingm || "0px"};
  }
  @media (max-width: 700px) {
    padding: ${(props) => props.Movilpadding || props.varpadding};
  }
  @media (max-width: 400px) {
    min-height: ${(props) => props.smMinHeight || props.varminheight };
  }
`;


export const Littleline = styled.div`
  min-height: ${(props) => props.varminheight || "4px"};
  max-width: ${(props) => props.varmaxwidth || "auto"};
  width: ${(props) => props.varwidth || "auto"};
  background-color: ${(props) => props.backgroundcolor || "#B3282D"};
  margin: ${(props) => props.margin || "0px"};

  @media (max-width: 700px) {
    margin: ${(props) => props.marginMovil || props.margin };
  }
  @media (max-width: 500px) {
    min-height: ${(props) => props.smMinHeight || props.varminheight || "4px"};
    width: ${(props) => props.smWidth || props.varwidth || "auto"};

  }
`;

//Enlaces
export const Enlace = styled.a`
  color: ${(props) => props.color || "white"};
  text-decoration: ${(props) => props.decoration || "none"};
`;