import React from 'react';
import CustomizedAccordions from '../Accordion';
import { RedHatDisplay } from '../Styled';

function AcordeonClutch() {
    // Inicializa el estado de expansión en 'true' para que el acordeón esté abierto al cargar la página
    const [expanded, setExpanded] = React.useState(true);

    // Los detalles para AccordionDetails
    const details = [
        <RedHatDisplay
            fontSize="18px"
            fontSizeMobile="14px"
            textAlign="left"
            lineHeight="1.2em"
            smFontSize= '12px'
        >
            <strong><em>Cambio de Prensa:</em></strong> Garantiza que el embrague funcione suavemente y sin problemas.
        </RedHatDisplay>,
        <RedHatDisplay
            fontSize="18px"
            fontSizeMobile="14px"
            textAlign="left"
            lineHeight="1.2em"
            smFontSize= '12px'
        >
            <strong><em>Cambio de Balero Collarín:</em></strong> Una pieza clave para un embrague sin ruidos y eficiente.
        </RedHatDisplay>,
        <RedHatDisplay
            fontSize="18px"
            fontSizeMobile="14px"
            textAlign="left"
            lineHeight="1.2em"
            smFontSize= '12px'
        >
            <strong><em>Cambio Completo de Kit de Clutch:</em></strong> Si tu clutch ya dio todo lo que tenía, te ponemos uno nuevo para que tu carro vuelva a andar como nuevo.
        </RedHatDisplay>,
    ];

    // Función para manejar el cambio de expansión del acordeón
    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded ? true : false);
    };

    return (
        <CustomizedAccordions
            header={
                <RedHatDisplay
                    fontSize="20px"
                    weight="700"
                    lineHeight="1.3em"
                    color="#fff"
                    smFontSize= '12px'
                >
                    Nuestro Servicio de Kit de Clutch Incluye
                </RedHatDisplay>
            }
            details={details}
            expanded={expanded}
            onChange={handleAccordionChange}
        />
    );
}

export default AcordeonClutch;