import React from 'react'
import './Error404.scss'
import { BtnRed, RedHatDisplay } from '../Styled'
import { useNavigate } from 'react-router-dom';

const Error404Component = () => {

  let arrow = require('../../assets/arrow_white.png')

  const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    };
  return (
    <section className='graciasComponent'>
      <div className='contentBox'>
        <img src={arrow} className='arrow' alt='arrow'></img>
        <RedHatDisplay
              margin = '16px 0 0 0'
              fontSize = "55.44px"
              color ='#fff'
              lineHeight = "1.2em" 
              textAlign = "left"
              weight = '600'
              marginsmMovil = '33px'
              smFontSize = '20px'
              smMargin = '30px 0 0'
              smTextAlign = 'center'
              >
                Opss!
        </RedHatDisplay>
        <RedHatDisplay
              margin = '16px 0 0 0'
              fontSize = "55.44px"
              color ='#fff'
              lineHeight = "1.2em" 
              textAlign = "left"
              weight = '600'
              marginsmMovil = '33px'
              smFontSize = '20px'
              smMargin = '30px 0 0'
              smTextAlign = 'center'
              >
                404
        </RedHatDisplay>
        <RedHatDisplay
              margin = '16px 0 0 0'
              fontSize = "30px"
              color ='#fff'
              lineHeight = "1.2em" 
              textAlign = "left"
              weight = '300'
              marginsmMovil = '33px'
              smFontSize = '20px'
              smMargin = '30px 0 0'
              smTextAlign = 'center'
              >
                Parece que esta página no existe.
        </RedHatDisplay>
        <BtnRed
                  type='button'
                  onClick={handleGoHome}
                  varwidth = "40%"
                  varmaxwidth = "155px"
                  varmargin = "16px 0 0 0"
                  fontSizeMobile = '12px'
                  smPadd = '12px 20px'
                  smWidth  = '44%'
                >
                  Volver al Inicio
        </BtnRed>
      </div>
    </section>
  )
}

export default Error404Component