import React from 'react';
import CustomizedAccordions from '../Accordion';
import { RedHatDisplay } from '../Styled';

function AcordeonEnfriamiento() {
    // Inicializa el estado de expansión en 'true' para que el acordeón esté abierto al cargar la página
    const [expanded, setExpanded] = React.useState(true);

    // Los detalles para AccordionDetails
    const details = [
        <RedHatDisplay
            fontSize="18px"
            fontSizeMobile="14px"
            textAlign="left"
            lineHeight="1.2em"
            smFontSize= '12px'
        >
            <strong><em>Mantenimiento y Reparación de Radiadores:</em></strong> Evita el sobrecalentamiento manteniendo tu radiador en perfecto estado.
        </RedHatDisplay>,
        <RedHatDisplay
            fontSize="18px"
            fontSizeMobile="14px"
            textAlign="left"
            lineHeight="1.2em"
            smFontSize= '12px'
        >
            <strong><em>Cambio de Anticongelante:</em></strong> Un servicio esencial para proteger tu motor en cualquier clima.
        </RedHatDisplay>,
        <RedHatDisplay
            fontSize="18px"
            fontSizeMobile="14px"
            textAlign="left"
            lineHeight="1.2em"
            smFontSize= '12px'
        >
            <strong><em>Reemplazo de Termostato:</em></strong> Fundamental para regular la temperatura de tu motor.
        </RedHatDisplay>
    ];

    // Función para manejar el cambio de expansión del acordeón
    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded ? true : false);
    };

    return (
        <CustomizedAccordions
            header={
                <RedHatDisplay
                    fontSize="20px"
                    weight="700"
                    lineHeight="1.3em"
                    color="#fff"
                    smFontSize= '12px'
                >
                    Nuestros Servicios de Enfriamiento Incluyen:
                </RedHatDisplay>
            }
            details={details}
            expanded={expanded}
            onChange={handleAccordionChange}
        />
    );
}

export default AcordeonEnfriamiento;