import React from 'react';
import CustomizedAccordions from '../Accordion';
import { RedHatDisplay } from '../Styled';

function AcordeonSuspension() {
    // Inicializa el estado de expansión en 'true' para que el acordeón esté abierto al cargar la página
    const [expanded, setExpanded] = React.useState(true);

    // Los detalles para AccordionDetails
    const details = [
        <RedHatDisplay
            fontSize="18px"
            fontSizeMobile="14px"
            textAlign="left"
            lineHeight="1.2em"
            smFontSize= '12px'
        >
            <strong><em>¿Tu carro se siente brincón?:</em></strong> Eso es señal de amortiguadores gastados, y aquí los cambiamos por unos buenos y nuevos.
        </RedHatDisplay>,
        <RedHatDisplay
            fontSize="18px"
            fontSizeMobile="14px"
            textAlign="left"
            lineHeight="1.2em"
            smFontSize= '12px'
        >
            <strong><em>¿Escuchas ruidos cuando das vuelta?:</em></strong> Podrían ser los bujes o las horquillas; nosotros los revisamos y cambiamos lo que haga falta.
        </RedHatDisplay>,
        <RedHatDisplay
            fontSize="18px"
            fontSizeMobile="14px"
            textAlign="left"
            lineHeight="1.2em"
            smFontSize= '12px'
        >
            <strong><em>¿Tu carro se inclina mucho en las curvas?:</em></strong> Eso puede ser la suspensión. Ven y te hacemos una revisión completa.
        </RedHatDisplay>
    ];

    // Función para manejar el cambio de expansión del acordeón
    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded ? true : false);
    };

    return (
        <CustomizedAccordions
            header={
                <RedHatDisplay
                    fontSize="20px"
                    weight="700"
                    lineHeight="1.3em"
                    color="#fff"
                    smFontSize= '12px'
                >
                    ¿Cómo sé que es la suspensión? ¡Aquí te Ayudamos!
                </RedHatDisplay>
            }
            details={details}
            expanded={expanded}
            onChange={handleAccordionChange}
        />
    );
}

export default AcordeonSuspension;