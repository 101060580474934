import React from 'react'
import { ContentBox, Griditem, Gridwrapper, Hero, Littleline, Logo, RedHatDisplay } from '../components/Styled'
import './Styles/Style.scss'

const Suspension = () => {
  let Hero_background = require('../assets/AUTO_DLG_Direccion_Hero.jpg')
  let arrowLeft = require('../assets/arrows_left.png')
  let arrowRight = require('../assets/arrows_right.png')
  let logo = require('../assets/AUTO_DLG_logo_bn.png')
  let solucion = require('../assets/AUTO_DLG_Direccion_Importancia.png')

  return (
    <div>
      <Hero
        varminheight='603px'
        style={{backgroundImage: `url(${Hero_background})`}}      >
          <Gridwrapper
            varwidth = "90%"
            varmaxwidth = '976px'
            varGridGap = "10px"
            gridTemplateColumns = "6.6% 84.6% 6.6%"
            columnsMovil = '10.6% 71.6% 10.6%'
          >
            <Griditem>
                <img src={arrowLeft} alt="arrow left" />
            </Griditem>
            <Griditem>
             <RedHatDisplay
                fontSize = "46px"
                color ='white'
                fontStyle = "italic"
                lineHeight = "1.2em"
                fontSizeMobile600 = '26px'
                movilLineHeight = '1.4em'
              >
                 Manejo Suave, Como Debe Ser
              </RedHatDisplay>
            </Griditem>
            <Griditem>
                <img src={arrowRight} alt="arrow rigth" />
            </Griditem>
          </Gridwrapper>
          <Logo 
                    varwidth = '60%'
                    varmaxwidth = '136px'
                    padd = '26px 0 0 0'
                    src={logo} alt="Logo AutoDLG"
          />
          <RedHatDisplay
            backgcolor = '#ffffff'
            maxWidth = '815px'
            width = '48%'
            fontSize = '20px'
            color = '#000000'
            varpadding = '20px 68px'
            margin = '31px 0 0'
            lineHeight = '1.3em'
            fontSizeMobile600 = '16px'
            widthMovil = '71%'
            MovilPadd = '20px 37px'
          >
            Tu carro no debe ser difícil de manejar. Sabemos bien que un buen manejo depende de una dirección en condiciones. Ya sea que tu carro tenga dirección electroasistida, mecánica o hidráulica, en AutoDLG te aseguramos un manejo suave y sin problemas. Si tu carro está duro para dar vuelta o si sientes que la dirección no responde como antes, somos tu solución.
          </RedHatDisplay>

      </Hero>
      <ContentBox
        display = 'flex'
        varminheight = '886px'
        Movilpadding = '81px 0 0'
        smMinHeight = '669px'
        varpadding = '60px 0 0'
        justifyContent = 'flex-end'
      >
        <ContentBox
          display = 'flex'
          alignItems = 'flex-end'
        >
          <RedHatDisplay
            fontSize = '28px'
            weight = '800'
            lineHeight = '1.5em'
            color='#F51313'
            justify = 'center'
            display = "flex"
            textAlign = 'left'
            smFontSize = '22px'
            smTextAlign = 'center'
          >
           Problemas Comunes en la Dirección:
          </RedHatDisplay>
          <Littleline
            backgroundcolor = "#000"
            varminheight = "4px"
            varwidth = "70%"
            varmaxwidth = '188px'
            margin = '6px 0 30px'
          />
        </ContentBox>
        
          <Gridwrapper
                varwidth = "100%"
                varmaxwidth = "1250px"
                varmargin = '38px 0 65px'
                varGridGap = "6.5%"
                gridTemplateColumns = "27.6% 30.08% 29.2%"
                varalignItems = 'start'
                columnsMovil600 = '100%'
                movilWidth = '80%'
                marginsmMovil = '38px 0 105px'
          >
                <Griditem
                  backgcolor = "#FFFFFF"
                  borderRadius = '30px'
                  shadow = '0px 8px 8px rgba(119, 33, 107, 0.2)'
                  padd = "20px"
                  width = "auto"
                  maxWidth = '100%'
                >
                 
                  <RedHatDisplay
                    fontSize = '22px'
                    weight = '600'
                    color='#000'
                    textAlign = 'left'
                    maxWidth = "100%"
                    lineHeight = '1.3em'
                    fontSizeMobile = '16px'
                    smFontSize = '14px'
                  >
                    <strong>¿Dirección dura o que no responde?</strong> Eso no está bien, ven para darle una checada.
                  </RedHatDisplay>
                </Griditem>
                <Griditem
                  backgcolor = "#FFFFFF"
                  borderRadius = '30px'
                  shadow = '0px 8px 8px rgba(119, 33, 107, 0.2)'
                  padd = "20px"
                  width = "auto"
                  maxWidth = '100%'
                >
                  
                  <RedHatDisplay
                    fontSize = '22px'
                    weight = '600'
                    lineHeight = '1.3em'
                    color='#000'
                    textAlign = 'left'
                    maxWidth = "100%"
                    fontSizeMobile = '16px'
                    smFontSize = '14px'
                  >
                    <strong>¿Ruidos al girar el volante?</strong> No dejes que eso siga, puede ser algo simple o algo que necesita atención rápida.
                  </RedHatDisplay>
                </Griditem>
                <Griditem
                  backgcolor = "#FFFFFF"
                  borderRadius = '30px'
                  shadow = '0px 8px 8px rgba(119, 33, 107, 0.2)'
                  padd = "20px"
                  width = "auto"
                  maxWidth = '100%'
                >
                  
                  <RedHatDisplay
                    fontSize = '22px'
                    weight = '600'
                    lineHeight = '1.3em'
                    color='#000'
                    textAlign = 'left'
                    maxWidth = "100%"
                    fontSizeMobile = '16px'
                    smFontSize = '14px'
                  >
                    <strong>¿Vibraciones o movimientos raros al manejar?</strong> Eso puede ser señal de problemas en la dirección. Mejor revísalo pronto.
                  </RedHatDisplay>
                </Griditem>
              </Gridwrapper>
              <img src={solucion} alt='solucion'></img>
      </ContentBox>
      
    </div>
  )
}

export default Suspension