import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { DateRange } from '@mui/icons-material';
import { Logo } from '../Styled';
import './Header.scss';

// Define un objeto de mapeo que asocie cada página con su URL correspondiente
const pageToUrlMap = {
    'Home': '/',
    'Mantenimiento': '/mantenimiento',
    'Frenos': '/frenos',
    'Escáner': '/escaner',
    'Suspensión': '/suspension',
    'Inyectores': '/inyectores',
    'Dirección': '/direccion',
    'Enfriamiento': '/enfriamiento',
    'Clutch': '/clutch',
    'Rodamiento': '/rodamiento',
};

const pages = Object.keys(pageToUrlMap);

function ResponsiveAppBar() {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handlePageNavigation = (page) => {
        const url = pageToUrlMap[page];
        if (url) {
            navigate(url);
        }
    };

    const imageUrl = require('../../assets/AUTO_DLG_tu_taller_de_confianza_header.png');

    return (
        <AppBar position="static" style={{ backgroundColor: '#000', padding: '23px 0' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* Botón del menú para dispositivos móviles */}
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="menu"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>

                    {/* Centra el logo en dispositivos móviles y mantiene su posición original en escritorio */}
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            justifyContent: {
                                xs: 'center', // Centra el logo en vista móvil
                                md: 'flex-start' // Mantiene el logo a la izquierda en vista de escritorio
                            }
                        }}
                    >
                        <Link
                            to='/'
                            className='enlaceinicio'
                        >
                            <Logo
                                varmaxwidth="116px"
                                varwidth="93%"
                                smWidth ='76%'
                                src={imageUrl}
                            />
                        </Link>
                        
                    </Box>

                    {/* Menú para dispositivos móviles */}
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        {pages.map((page) => (
                            <MenuItem
                                key={page}
                                onClick={() => {
                                    handlePageNavigation(page);
                                    handleCloseNavMenu();
                                }}
                            >
                                <Typography textAlign="center">{page}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>

                    {/* Menú de navegación para dispositivos de escritorio */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={() => handlePageNavigation(page)}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>

                    {/* Icono de calendario, visible en todos los dispositivos */}
                    <Tooltip title="Ver calendario">
                        <a href="/calendario" className="iconCalendar" style={{ color: 'white' }}>
                            <DateRange />
                        </a>
                    </Tooltip>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
