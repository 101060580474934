// src/hooks/useScroll.js
import { useState, useEffect } from 'react';

const useScroll = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollTo = ({ top }) => {
    window.scrollTo({ top, behavior: 'smooth' });
  };

  return { scrollPosition, scrollTo };
};

export default useScroll;