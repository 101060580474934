import React from 'react'
import './Gracias.scss'
import { BtnRed, RedHatDisplay } from '../Styled'

const GraciasComponent = () => {

  let arrow = require('../../assets/arrows_left.png')

  const handleGoWhatsApp = () => {
    const phoneNumber = '528712611030'; // Replace with the actual phone number
    const whatsAppLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsAppLink, '_blank'); // Opens WhatsApp chat in a new tab
  };

  return (
    <section className='graciasComponent'>
      <div className='contentBox'>
        <img src={arrow} className='arrow' alt='arrow'></img>
        <RedHatDisplay
              margin = '16px 0 0 0'
              fontSize = "60px"
              color ='#fff'
              lineHeight = "1.2em" 
              textAlign = "left"
              weight = '600'
              marginsmMovil = '33px'
              smFontSize = '20px'
              smMargin = '30px 0 0'
              smTextAlign = 'center'
              >
                ¡Gracias!
        </RedHatDisplay>
        <RedHatDisplay
              margin = '16px 0 0 0'
              fontSize = "30px"
              color ='#fff'
              lineHeight = "1.2em" 
              textAlign = "left"
              weight = '300'
              marginsmMovil = '33px'
              smFontSize = '20px'
              smMargin = '30px 0 0'
              smTextAlign = 'center'
              >
                Reclama tu servicio agendando con nosotros.
        </RedHatDisplay>
        <BtnRed
                  type='button'
                  onClick={handleGoWhatsApp}
                  varwidth = "40%"
                  varmaxwidth = "155px"
                  varmargin = "16px 0 0 0"
                  fontSizeMobile = '12px'
                  smPadd = '12px 20px'
                  smWidth  = '44%'
                >
                  WhatsApp
        </BtnRed>
      </div>
    </section>
  )
}

export default GraciasComponent