import React from 'react'
import Precios from '../components/Precios/Precios'
import Visitanos from '../components/Visitanos/Visitanos';
import MisionVision from '../components/MisionVision/MisionVision';
import Servicios from '../components/Servicios/Servicios';

const Home = () => {
  return (
    <div>
      <Precios />
      <Servicios />
      <Visitanos />
      <MisionVision />
    </div>
  );
}

export default Home