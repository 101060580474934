import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BtnServicios, Griditem, Gridwrapper, RedHatDisplay } from '../Styled';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './Servicio.scss';
import useScroll from '../../hooks/ScrollNavigate';

const Servicios = () => {
    const { scrollTo } = useScroll(); // Get the scrollTo function from react-use-scroll
    const navigate = useNavigate(); // Get navigate function from React Router


    // Define el objeto de mapeo que asocia cada servicio con su URL
    const serviceToUrlMap = {
        'Mantenimiento': '/mantenimiento',
        'Escáner': '/escaner',
        'Suspensión': '/suspension',
        'Inyectores': '/inyectores',
        'Dirección': '/direccion',
        'Enfriamiento': '/enfriamiento',
        'Clutch': '/clutch',
        'Rodamiento': '/rodamiento',
    };

    // Define el manejador de eventos para manejar la redirección
    const handleClick = (servicio) => {
        const url = serviceToUrlMap[servicio]; // Obtén la URL correspondiente al servicio
        if (url) {
            scrollTo({ top: 0 }); // Scroll to top before navigating
            navigate(url); // Navigate to the desired page
        }
    };

    return (
        <div className='contentService'>
            <div className='title-servicio-div'>
                <RedHatDisplay
                    weight='800'
                    fontSize='20px'
                >
                    ¿Qué servicio requieres?
                </RedHatDisplay>
                <ChevronRightIcon />
            </div>
            <div className='servicio-div'>
                <Gridwrapper
                    varwidth="95%"
                    varmaxwidth="719px"
                    varGridGap="25px"
                    gridTemplateColumns='repeat(4, 1fr)'
                    columnsMovil600 = 'repeat(2, 1fr)'
                    tabletWidth = '69%'
                    gridgapTablet = '8%'
                >
                    {/* Cada Griditem contiene un BtnServicios con un manejador de eventos onClick */}
                    {Object.keys(serviceToUrlMap).map((servicio) => (
                        <Griditem key={servicio}>
                            <BtnServicios 
                              onClick={() => handleClick(servicio)}
                              fontSizeMobile = '13px'
                            >
                                {servicio}
                            </BtnServicios>
                        </Griditem>
                    ))}
                </Gridwrapper>
            </div>
        </div>
    );
};

export default Servicios;