import React from 'react'
import { BtnRed, ContentBox, Griditem, Gridwrapper, Hero, Littleline, Logo, RedHatDisplay } from '../components/Styled'
import './Styles/Style.scss'
import AcordeonMantenimiento from '../components/AcordeonServicios/AcordeonMantenimiento'

const Frenos = () => {
  let Hero_background = require('../assets/AUTO_DLG_Frenos_Hero.jpg')
  let arrowLeft = require('../assets/arrows_left.png')
  let arrowRight = require('../assets/arrows_right.png')
  let logo = require('../assets/AUTO_DLG_logo_bn.png')
  let solucion = require('../assets/AUTO_DLG_Frenos_Importancia.png')
  let servicios = require('../assets/AUTO_DLG_Frenos_Bottom.jpg')

  const handleDirectionClick = () => {
    const DirectionLink = `https://maps.app.goo.gl/uTcQhg5dqaTm87TN8`;
    window.open(DirectionLink, '_blank'); // Opens WhatsApp chat in a new tab
  };

  return (
    <div>
      <Hero
        varminheight='603px'
        style={{backgroundImage: `url(${Hero_background})`}}      >
          <Gridwrapper
            varwidth = "90%"
            varmaxwidth = '976px'
            varGridGap = "10px"
            gridTemplateColumns = "6.6% 84.6% 6.6%"
            columnsMovil = '10.6% 71.6% 10.6%'
          >
            <Griditem>
                <img src={arrowLeft} alt="arrow left" />
            </Griditem>
            <Griditem>
             <RedHatDisplay
                fontSize = "46px"
                color ='white'
                fontStyle = "italic"
                lineHeight = "115.6%"
                fontSizeMobile600 = '26px'
                movilLineHeight = '1.4em'
              >
                 Asegura tu frenado.
              </RedHatDisplay>
              
            </Griditem>
            <Griditem>
                <img src={arrowRight} alt="arrow rigth" />
            </Griditem>
          </Gridwrapper>
          <Logo 
                    varwidth = '60%'
                    varmaxwidth = '136px'
                    padd = '26px 0 0 0'
                    src={logo} alt="Logo AutoDLG"
          />
          <RedHatDisplay
            backgcolor = '#ffffff'
            maxWidth = '815px'
            width = '48%'
            fontSize = '20px'
            color = '#000000'
            varpadding = '20px 68px'
            margin = '31px 0 0'
            lineHeight = '1.3em'
            fontSizeMobile600 = '16px'
            widthMovil = '71%'
            MovilPadd = '20px 37px'
          >
            Los frenos son uno de los componentes más importantes para la seguridad de tu vehículo. En AutoDLG, nos especializamos en ofrecer servicios de frenos completos, garantizando tu seguridad y la de tus pasajeros. Nuestros expertos están equipados para atender todas tus necesidades de frenado.
          </RedHatDisplay>

      </Hero>
      <ContentBox
        display = 'flex'
        varminheight = '886px'
        Movilpadding = '81px 0 0'
        smMinHeight = '784px'
        varpadding = '60px 0 0'
        justifyContent = 'flex-end'
      >
        <ContentBox
          display = 'flex'
          alignItems = 'flex-end'
        >
          <RedHatDisplay
            fontSize = '28px'
            weight = '800'
            lineHeight = '1.5em'
            color='#F51313'
            justify = 'center'
            display = "flex"
            textAlign = 'left'
          >
            ¿Problemas con tus Frenos?
          </RedHatDisplay>
          <Littleline
            backgroundcolor = "#000"
            varminheight = "4px"
            varwidth = "70%"
            varmaxwidth = '188px'
            margin = '6px 0 30px'
          />
        </ContentBox>
       
          <Gridwrapper
                varwidth = "100%"
                varmaxwidth = "1199px"
                varmargin = '38px 0 65px'
                varGridGap = "6.8%"
                gridTemplateColumns = "28.77% 28.77% 28.77%"
                varalignItems = 'start'
                columnsMovil600 = '100%'
                movilWidth = '80%'
                marginsmMovil = '38px 0 105px'
          >
                <Griditem
                  backgcolor = "#FFFFFF"
                  borderRadius = '30px'
                  shadow = '0px 8px 8px rgba(119, 33, 107, 0.2)'
                  padd = "20px"
                  width = "auto"
                  maxWidth = '100%'
                >
                 
                  <RedHatDisplay
                    fontSize = '22px'
                    weight = '600'
                    color='#000'
                    textAlign = 'left'
                    maxWidth = "100%"
                    lineHeight = '1.3em'
                    fontSizeMobile = '16px'
                    smFontSize = '14px'
                  >
                    <strong>¿Frenado irregular o ruidoso?</strong> Nuestros especialistas pueden identificar y solucionar problemas como ruidos, vibraciones o respuesta lenta en los frenos.
                  </RedHatDisplay>
                </Griditem>
                <Griditem
                  backgcolor = "#FFFFFF"
                  borderRadius = '30px'
                  shadow = '0px 8px 8px rgba(119, 33, 107, 0.2)'
                  padd = "20px"
                  width = "auto"
                  maxWidth = '100%'
                >
                  
                  <RedHatDisplay
                    fontSize = '22px'
                    weight = '600'
                    lineHeight = '1.3em'
                    color='#000'
                    textAlign = 'left'
                    maxWidth = "100%"
                    fontSizeMobile = '16px'
                    smFontSize = '14px'
                  >
                    <strong>¿Desgaste de balatas?</strong> Te ofrecemos las mejores opciones de reemplazo para tu tipo de vehículo.
                  </RedHatDisplay>
                </Griditem>
                <Griditem
                  backgcolor = "#FFFFFF"
                  borderRadius = '30px'
                  shadow = '0px 8px 8px rgba(119, 33, 107, 0.2)'
                  padd = "20px"
                  width = "auto"
                  maxWidth = '100%'
                >
                 
                  <RedHatDisplay
                    fontSize = '22px'
                    weight = '600'
                    lineHeight = '1.3em'
                    color='#000'
                    textAlign = 'left'
                    maxWidth = "100%"
                    fontSizeMobile = '16px'
                    smFontSize = '14px'
                  >
                    <strong>¿Luz de advertencia de frenos activada?</strong> Realizamos un diagnóstico preciso para detectar cualquier anomalía.            
                  </RedHatDisplay>
                </Griditem>
              </Gridwrapper>
              <img src={solucion} alt='solucion mantenimiento'></img>
      </ContentBox>
      <Gridwrapper
        varwidth = "100%"
        varmaxwidth = "1440px"
        varGridGap = "0%"
        gridTemplateColumns = "58.19% 41.8%"
        columnsMovil = '100%'
      >
        <Griditem>
          <ContentBox
            varpadding = '92px 96px 84px 84px'
            maxwidhtMovil = '89%'
            margin = '0px auto'
            Movilpadding = '40px 0 0'
          >
            <AcordeonMantenimiento />
            <Gridwrapper
              varwidth = "100%"
              varmargin = '38px 0 0px'
              varGridGap = "15px"
              gridTemplateColumns = "48.9% 48.9%"
              columnsMovilsm = '47.9% 50.9%'
              gridgapMovil = '10px'
              marginsmMovil = '38px auto'
            >
              <Griditem>
                <BtnRed 
                  backgcolor = '#F51313'
                  vargridgap = '8px'
                  fontSizeMobile = '12px'
                  varwidth = '-webkit-fill-available'
                >
                  Agenda tu Revisión de Frenos
                </BtnRed>
              </Griditem>
              <Griditem>
              <BtnRed 
                  type='submit'
                  onClick={handleDirectionClick}
                  backgcolor = '#F51313'
                  vargridgap = '8px'
                  fontSizeMobile = '12px'
                  varwidth = '-webkit-fill-available'
                >
                  Llévame al taller
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_185_845)">
                  <path d="M12.5 2C8.63 2 5.5 5.13 5.5 9C5.5 13.17 9.92 18.92 11.74 21.11C12.14 21.59 12.87 21.59 13.27 21.11C15.08 18.92 19.5 13.17 19.5 9C19.5 5.13 16.37 2 12.5 2ZM12.5 11.5C11.12 11.5 10 10.38 10 9C10 7.62 11.12 6.5 12.5 6.5C13.88 6.5 15 7.62 15 9C15 10.38 13.88 11.5 12.5 11.5Z" fill="white"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_185_845">
                  <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                  </clipPath>
                  </defs>
                  </svg>
                </BtnRed>
              </Griditem>
            </Gridwrapper>
          </ContentBox>
        </Griditem>
        <Griditem 
          varminheight = '671px'
          tabletHeight = '452px'
          movilHeight = '352px'
          padd = '50px 0 0'
          display = 'flex'
          justifyContent = 'flex-start'
          style={{backgroundImage: `url(${servicios})`, backgroundSize: 'cover', backgroundRepeat:'no-repeat'}}
        >
          <RedHatDisplay
            fontSize = '40px'
            weight = '300'
            lineHeight = '1.3em'
            color='#fff'
            width = "80%"
            fontSizeMobile = '28px'
            fontSizeMobile600 = '38px'
            smFontSize = '28px'
          >
            No comprometas tu seguridad y la de tu familia, contáctanos hoy mismo.
          </RedHatDisplay>
        </Griditem>
      </Gridwrapper>

      
    </div>
  )
}

export default Frenos