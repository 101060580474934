import React from 'react'
import './MisionVision.scss'
import { Griditem, Gridwrapper, Littleline, RedHatDisplay } from '../Styled'

const MisionVision = () => {
  let logo = require('../../assets/AUTO_DLG_logo_bn.png')
  let arrowLeft = require('../../assets/arrows_left.png')
  let arrowRigth = require('../../assets/arrows_right.png')
  return (
    <section className='section-mision-vision'>
        <div className='contentImage' />
        <Gridwrapper
          varwidth = "75%"
          varmaxwidth = "1036px"
          varGridGap = "22.5%"
          gridTemplateColumns = "38.6% 38.6%"
          varmargin = "-164px 0 0 0"
          columnsMovil = '48.6% 48.6%'
          tabletWidth = '80%'
          gridgapTablet = '6%'
          columnsMovilsm = '100%'
          marginsmMovil = '-558px 0 0'
        >
          <Griditem
            className='columnMV'
          >
            <img src={logo} alt="AutoDLG" className='logo' style={{margin: '0 auto 26px'}}/>
            <Gridwrapper
              varwidth = "100%"
              varmaxwidth = "1036px"
              varGridGap = "14.6%"
              gridTemplateColumns = "16.25% 32.25% 16.25%"
            >
              <Griditem>
                <img src={arrowLeft} alt='arrow-left'  className='arrow'/>
              </Griditem>
              <Griditem>
                <RedHatDisplay
                  fontSize = "46px"
                  color ='white'
                  fontStyle = "italic"
                  lineHeight = "1.2em"
                  margin = "0px auto"
                  fontSizeMobile600 = '33px'
                >
                  Visión
                </RedHatDisplay>
              </Griditem>
              <Griditem>
                <img src={arrowRigth} alt='arrow-rigth' className='arrow'/>
              </Griditem>
            </Gridwrapper>
            <RedHatDisplay
             margin = '54px 0 0 0'
             fontSize = "20px"
             color ='black'
             lineHeight = "1.2em" 
             textAlign = "left"
             weight = '300'
             colorMovil = '#fff'
             marginsmMovil = '33px'
             smFontSize = '14px'
             smMargin = '30px 0 0'
             smTextAlign = 'center'
            >
              Ser líderes en la industria automotriz, reconocidos por excelencia y atención al cliente. Expandirnos nacional e internacionalmente, estableciendo estándares de referencia en mantenimiento y reparación de vehículos con un enfoque sostenible.
            </RedHatDisplay>
          </Griditem>
          <Griditem
           className='columnMV'
           >
            <img src={logo} alt="AutoDLG" className='logo' style={{margin: '0 auto 26px'}}/>
            <Gridwrapper
              varwidth = "100%"
              varmaxwidth = "1036px"
              varGridGap = "14.6%"
              gridTemplateColumns = "16.25% 32.25% 16.25%"
              
            >
              <Griditem>
                <img src={arrowLeft}  alt='arrow-left' className='arrow'/>
              </Griditem>
              <Griditem>
                <RedHatDisplay
                  fontSize = "46px"
                  color ='white'
                  fontStyle = "italic"
                  lineHeight = "1.2em"
                  margin = "0px auto"
                  fontSizeMobile600 = '33px'
                >
                  Misión
                </RedHatDisplay>
              </Griditem>
              <Griditem>
                <img src={arrowRigth} alt='arrow-rigth' className='arrow'/>
              </Griditem>
            </Gridwrapper>
            <RedHatDisplay
              margin = '54px 0 0 0'
              fontSize = "20px"
              color ='black'
              lineHeight = "1.2em" 
              textAlign = "left"
              weight = '300'
              marginsmMovil = '33px'
              smFontSize = '14px'
              smMargin = '30px 0 0'
              smTextAlign = 'center'
              colorMovil = '#fff'
            >
              Proveer servicios automotrices de alta calidad que aseguren seguridad y satisfacción, usando tecnología avanzada y personal capacitado. Ofrecer soluciones eficientes para mantener los vehículos en óptimas condiciones.
            </RedHatDisplay>
          </Griditem>
        </Gridwrapper>
        <div className='compromiso'>
          
          <RedHatDisplay
            fontSize = '60px'
            weight = '800'
            lineHeight = '1.5em'
            color='#F51313'
            justify = 'center'
            display = "flex"
            textAlign = 'left'
            fontSizeMobile600 = '28px'
          >
            Compromiso AutoDLG
          </RedHatDisplay>
          <Littleline
            backgroundcolor = "#000"
            varminheight = "7px"
            varwidth = "70%"
            varmaxwidth = '236px'
            margin = '0 0 104px'
            marginMovil = '0 0 46px'
            smMinHeight = '4px'
            smWidth = '47%'
          />
          <Gridwrapper
            varwidth = "100%"
            varGridGap = "3.2%"
            gridTemplateColumns = "4.7% 83.98% 4.7%"
            columnsMovil600 = '9.7% 72.98% 9.7%'
          >
            <Griditem>
              <img src={arrowLeft} alt='arrow-left' className='arrow'/>
            </Griditem> 
            <Griditem>
              <Gridwrapper
                varwidth = "100%"
                varmaxwidth = "100%"
                varGridGap = "5%" 
                gridTemplateColumns = "29.8% 29.8% 29.8%"
                columnsMovil600 = '100%'
              >
                <Griditem
                  backgcolor = "#FFFFFF"
                  borderRadius = '30px'
                  shadow = '0px 8px 8px rgba(119, 33, 107, 0.2)'
                  padd = "20px 20px 50px"
                  width = "auto"
                  maxWidth = '100%'
                >
                  <RedHatDisplay
                    fontSize = '20px'
                    weight = '600'
                    lineHeight = '1.0em'
                    color='#F51313'
                    varpadding = '0 0 20px'
                    textAlign = 'left'
                    fontSizeMobile600 = '17px'
                  >
                    Repuestos de Calidad:
                  </RedHatDisplay>
                  <RedHatDisplay
                    fontSize = '20px'
                    weight = '600'
                    color='#000'
                    textAlign = 'left'
                    maxWidth = "100%"
                    lineHeight = '1.3em'
                    fontSizeMobile600 = '14px'
                  >
                    Utilizamos solo las <strong>mejores marcas y materiales</strong> para asegurar tu seguridad.
                  </RedHatDisplay>
                </Griditem>
                
                <Griditem
                  backgcolor = "#FFFFFF"
                  borderRadius = '30px'
                  shadow = '0px 8px 8px rgba(119, 33, 107, 0.2)'
                  padd = "20px 20px 40px"
                  width = "auto"
                  maxWidth = '100%'
                >
                  <RedHatDisplay
                    fontSize = '20px'
                    weight = '600'
                    lineHeight = '1.0em'
                    color='#F51313'
                    varpadding = '0 0 20px'
                    textAlign = 'left'
                    fontSizeMobile600 = '17px'
                  >
                    Expertos Certificados: 
                  </RedHatDisplay>
                  <RedHatDisplay
                    fontSize = '20px'
                    weight = '600'
                    lineHeight = '1.3em'
                    color='#000'
                    textAlign = 'left'
                    maxWidth = "100%"
                    fontSizeMobile600 = '14px'
                  >
                    Nuestro equipo está <strong>altamente capacitado</strong> en sistemas de frenado modernos y tradicionales.                  </RedHatDisplay>
                </Griditem>
                <Griditem
                  backgcolor = "#FFFFFF"
                  borderRadius = '30px'
                  shadow = '0px 8px 8px rgba(119, 33, 107, 0.2)'
                  padd = "20px 20px 50px"
                  width = "auto"
                  maxWidth = '100%'
                >
                  <RedHatDisplay
                    fontSize = '20px'
                    weight = '600'
                    lineHeight = '1.3em'
                    color='#F51313'
                    varpadding = '0 0 20px'
                    textAlign = 'left'
                    fontSizeMobile600 = '17px'
                  >
                    Diagnóstico Preciso:
                  </RedHatDisplay>
                  <RedHatDisplay
                    fontSize = '20px'
                    weight = '600'
                    lineHeight = '1.3em'
                    color='#000'
                    textAlign = 'left'
                    maxWidth = "100%"
                    fontSizeMobile600 = '14px'
                  >
                    Equipos de última generación para un <strong>diagnóstico y reparación eficientes.</strong>
                  </RedHatDisplay>
                </Griditem>
              </Gridwrapper>
            </Griditem>
            <Griditem>
              <img src={arrowRigth} alt='arrow-rigth' className='arrow'/>
            </Griditem> 
          </Gridwrapper>

        </div>
    </section>
  )
}

export default MisionVision