import './App.css';
import React from 'react';
import Home from './pages/Home';
import Footer from './components/Footer/Footer';
import Bottom from './components/Bottom/Bottom';
import ResponsiveAppBar from './components/Header/Header';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Mantenimiento from './pages/Mantenimiento';
import Escaner from './pages/Escaner';
import Suspension from './pages/Suspension';
import Inyectores from './pages/Inyectores';
import Clutch from './pages/Clutch';
import Direccion from './pages/Direccion';
import Enfriamiento from './pages/Enfriamiento';
import Frenos from './pages/Frenos';
import Rodamiento from './pages/Rodamiento';
import Gracias from './pages/Gracias';
import ErrorHeader from './components/ErrorHeader/ErrorHeader';
import Error404 from './pages/404';

function Layout() {
    const location = useLocation();
    const isGraciasPage = location.pathname === '/gracias';
    const isErrorPage = location.pathname === '/404';

    return (
        <>
            {isErrorPage ? <ErrorHeader /> : <ResponsiveAppBar />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/mantenimiento" element={<Mantenimiento />} />
                <Route path="/escaner" element={<Escaner />} />
                <Route path="/suspension" element={<Suspension />} />
                <Route path="/inyectores" element={<Inyectores />} />
                <Route path="/clutch" element={<Clutch />} />
                <Route path="/direccion" element={<Direccion />} />
                <Route path="/enfriamiento" element={<Enfriamiento />} />
                <Route path="/frenos" element={<Frenos />} />
                <Route path="/rodamiento" element={<Rodamiento />} />
                <Route path="/gracias" element={<Gracias />} />
                <Route path="*" element={<Error404 />} /> {/* Ruta para manejar páginas no encontradas */}
            </Routes>
            {!isGraciasPage && !isErrorPage && <Bottom />}
            {!isGraciasPage && !isErrorPage && <Footer />}
        </>
    );
}

function App() {
    return (
        <Router>
            <Layout />
        </Router>
    );
}

export default App;