import React from 'react'
import './Precios.scss'
import { Griditem, Gridwrapper, Logo, RedHatDisplay } from '../Styled'
import Formulario from '../Formulario/Formulario'

const Precios = () => {
    let arrowLeft = require('../../assets/arrows_left.png')
    let arrowRight = require('../../assets/arrows_right.png')
    let logo = require('../../assets/AUTO_DLG_logo_bn.png')
  return (
    <section className='section-precios'>
        <Gridwrapper
            varwidth = "86%"
            varmaxwidth = "1222px"
            varGridGap = "1.5%"
            gridTemplateColumns = "46% 54%"
            columnsMovil600 = '100%'
        >
            <Griditem
                display = 'flex'
            >
                <Gridwrapper
                    varwidth = "100%"
                    varGridGap = "31px"
                    gridTemplateColumns = "9.2% 69.6% 9.2%"
                    padd800 = '0 0 34px'
                >
                    <Griditem>
                        <img src={arrowLeft} alt="arrow left" className='arrow'/>
                    </Griditem>
                    <Griditem>
                        <RedHatDisplay
                            fontSize = "40px"
                            color ='white'
                            fontStyle = "italic"
                            lineHeight = "1.2em"
                            maxWidth = "494px"
                            fontSizeMobile = '29px'
                        >
                            Precios locales, con la seguridad y excelencia de agencia
                        </RedHatDisplay>
                    </Griditem>
                    <Griditem>
                        <img src={arrowRight} alt="arrow rigth"  className='arrow'/>
                    </Griditem>
                </Gridwrapper>
                <Logo 
                    varwidth = '60%'
                    varmaxwidth = '136px'
                    padd = '26px 0 0 0'
                    smWidth = '22%'
                    src={logo} alt="Logo AutoDLG"
                />
            </Griditem>
            <Griditem>
                <Formulario/>
            </Griditem>

        </Gridwrapper>
    </section>
  )
}

export default Precios